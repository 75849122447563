import { useEffect } from "react";

const SocialLanding = () => {
  const isRunningInWebview = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    // Use regex for cleaner, more flexible matching
    const isWebView = /fban|fbav|instagram|threads/i.test(userAgent);
    return isWebView;
  };

  const getIntentLink = () => {
    const baseDomain =
      process.env.REACT_APP_ENVIRONMENT === "prod"
        ? "slipper.fi"
        : "staging.slipper.fi";
    return `intent://${baseDomain}/login#Intent;scheme=https;package=com.android.chrome;end`;
  };

  useEffect(() => {
    const intentLink = getIntentLink();

    if (isRunningInWebview()) {
      // Redirect the user to the external browser if in webview (Threads, FB, Instagram)
      window.location.href = intentLink;
    } else {
      // Redirect to the login page in a normal browser
      window.location.href = `https://${process.env.REACT_APP_ENVIRONMENT === "production" ? "slipper.fi" : "staging.slipper.fi"}/login`;
    }
  }, []);

  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <span aria-busy="true"> </span>
      {/* <h1>Ohjataan ulkoiseen selaimeen...</h1> */}
      <p>
  Mikäli uudelleenohjaus epäonnistui, avaa tästä{" "}
  <span
    style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
    onClick={() => {
      const url =
        process.env.REACT_APP_ENVIRONMENT === "prod"
          ? "https://slipper.fi/login"
          : "https://staging.slipper.fi/login";
      window.open(url, "_blank");
    }}
  >
    {`https://${process.env.REACT_APP_ENVIRONMENT === "prod" ? "slipper.fi" : "staging.slipper.fi"}/login`}
  </span>{" "}
  ulkoisessa selaimessasi. Slipperin sisäänkirjautuminen ei toimi Facebookin, Instagramin tai Threadsin sisäisissä selaimissa.
</p>
<p>Mikäli tämäkään ei ohjaa sinua ulkoiseen selaimeen, syötä osoite <b>slipper.fi</b> sinne käsin</p>

    </div>
  );
};

export default SocialLanding;
